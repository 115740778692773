import React, { useEffect } from 'react'
import BlockContent from '../components/block-content'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Figure from '../components/figure'
import { format } from 'date-fns'
import nb from 'date-fns/locale/nb'
import style from '../components/blog-post.module.css'
import { mapEdgesToNodes } from '../utils/helpers'
import BlogPostPreview from '../components/blog-post-preview'
import Breadcrumbs from '../components/breadcrumbs'
import slugify from 'slugify'
import { BlockContentToText } from '../utils/block-content-to-text'

const BlogPostTemplate = (props) => {
  const { data, errors } = props
  const blog = data && data.blog
  const postsFromSameCategory = data && data.postsFromSameCategory && mapEdgesToNodes(data.postsFromSameCategory)
  const metaDescription = (blog.seo && blog.seo.description) || BlockContentToText(blog.lead)

  return (
    <>
      {blog && <SEO page={blog} description={metaDescription} />}
      <Breadcrumbs crumbs={[
        { title: 'Blogg', slug: { current: 'blogg' } },
        blog.category,
        blog
      ]} />
      <div className='bg-peach-lighter bg-opacity-50 header-offset'>
        <div className='max-w-screen-lg mx-auto p-5 md:px-10 mt-10 md:mt-20 lg:px-10'>
          <div className="mb-8">
            {blog.category.title && (
              <Link to={`/blogg/${blog.category.slug.current}`}>
                <div className='mb-3 uppercase tracking-wide label bg-red-light text-red inline-block rounded-sm py-1 px-2'>
                  {blog.category.title}
                </div>
              </Link>
            )}
            <h1>{blog.title}</h1>
            {blog.lead && (
              <BlockContent className='lead mb-8' blocks={blog.lead} />
            )}
            <div className='meta'>
              <small>
                {blog.updatedAt && (
                  <span className='mr-8 md:mr-16'>{format(new Date(blog.updatedAt), 'Do MMMM YYYY', { locale: nb })}</span>
                )}
                {blog.author && (
                  <span>
                    <span>Skrevet av: </span>
                    <Link to={'/vare-leger#' + slugify(blog.author.name, {lower: true})}>
                      {blog.author.name}
                    </Link>
                  </span>
                )}
              </small>
            </div>
          </div>
          <div className={`mb-10 md:mb-15 ${style.article}`}>
            <Figure node={blog.image} />
          </div>
          {blog.body && (
            <article className={style.article}>
              <BlockContent className='body editorial' blocks={blog.body} />
            </article>
          )}
        </div>
      </div>
      {postsFromSameCategory.length > 0 && (
        <div className='bg-peach-lighter bg-opacity-50 py-12 md:py-20'>
          <div className='max-w-screen-xl mx-auto'>
            <h2 className='font-serif font-bold text-primary text-center mb-20'>Lyst til å lære mer?</h2>
            <div className='mx-5 md:mx-10 lg:mx-20 grid md:grid-cols-2 md:gap-10 lg:gap-16'>
              {postsFromSameCategory.map(node => (
                <BlogPostPreview className='w-full' key={node.id} {...node} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogQuery($id: String!, $categoryId: String!) {
    blog: sanityBlog(id: {eq: $id}) {
      id
      title
      updatedAt
      estimatedReadingTime
      image: featuredImage {
        alt
        caption
        asset {
          fluid(maxWidth: 944) {
            ...GatsbySanityImageFluid
          }
        }
      }
      author {
        name
      }
      category {
        title
        slug {
          current
        }
      }
      lead: _rawLead(resolveReferences:{maxDepth:100})
      body: _rawBody(resolveReferences:{maxDepth:100})
      seo {
        title
        description
      }
    }
    postsFromSameCategory: allSanityBlog(filter: {
      category: { id: { eq: $categoryId } },
      id: { ne: $id }
    }, limit: 2) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }
  }
`